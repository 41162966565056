import { gsap } from "gsap";
import Player from "@vimeo/player";
import ModalVideo from "modal-video";
import Swiper from "swiper"
import { Navigation, Pagination } from 'swiper/modules';
import {Mousewheel} from 'swiper/modules';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import {initVideoControls} from "./video-player";
import {jsLazyLoadImage, navBarFixed, newsTicker, initAccordion, initModal} from "./global";
import {scrollToHash, selectEl, selectAllEl, testimonialAnimation} from "./utils";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin)

let isPotraitMode = false;

// window.onbeforeunload = function () {
//     window.scrollTo(0, 0);
// }

window.addEventListener('load', function() {
    if (!document.body.classList.contains("home")) return; // Do not execute on pages other than home page

    jsLazyLoadImage()
    newsTicker()
    navBarFixed()
    initVideoControls()
    ScrollTrigger.config({
        autoRefreshEvents: "DOMContentLoaded,load"
    });
    ScrollSmoother.create({
        smooth: 1.5,
        smoothTouch: 0.1
    })
})
function containerSpacing() {
    const container = selectEl('.tp-dummy-container');
    if(!container) return;
    const containerWidth =  container.offsetWidth;
    const windowWidth = selectEl('body').clientWidth;
    const containerSpacing = (windowWidth - containerWidth)/2;

    const articlesContainer = selectAllEl('.tp-articles-container');
    articlesContainer.forEach(ac => {
        ac.style.paddingLeft = containerSpacing + 'px';
    })
}
function articlesCard() {
    const slider = selectEl('.explore-slider')
    if(!slider) return;
    const exploreSliderHeight = slider.clientHeight;
    const exploreCardSelector = selectEl('.explore-card');

    for (let i = 0; i < exploreCardSelector.length; i++) {
        exploreCardSelector[i].style.height = exploreSliderHeight + 'px';
    }
}

document.addEventListener("DOMContentLoaded", function () {
    new ModalVideo('.js-modal-btn');
    exploreSlider()
    sliderSection()
    initAccordion()
    initPopupLinks()
    initModal()
});


export function initPopupLinks() {
    // Payment links popup start
    const removeActive = ( ) => selectEl('.payment-options_card li.active')?.classList?.remove('active')

    document.addEventListener("click", (e) => {
        if(e.target.classList.contains('is-underline')) {
            return
        }
        removeActive()
    });

    const popupLinks = selectAllEl(".payment-options_card li .clickable");
    popupLinks.forEach((item, index) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            removeActive()
            const classList = e.target.closest('li').classList
            classList.toggle('active')

            if(classList.contains('active')) {
                e.target.closest('.payment-options_card').classList.add('active')
            } else {
                e.target.closest('.payment-options_card').classList.remove('active')
            }
        });
    });
}

export function exploreSlider() {
    const fourSlide = new Swiper(".explore-slider", {
        modules: [Mousewheel],
        grabCursor: true,
        spaceBetween: 20,
        centeredSlides: false,
        slidesPerView: 1.3,
        loop: false,
        mousewheel: {
            releaseOnEdges: true,
        },
        breakpoints: {
            567: {
                slidesPerView: 2.5,
            },
            1024: {
                slidesPerView: 3.5,
            },
        },
    });
    containerSpacing();
    articlesCard();
}
export function sliderSection() {
    const fourSlideTest = new Swiper(".included-slider", {
        loop: true,
        slidesPerView: "1",
        modules: [Navigation, Pagination],
        autoHeight: true, //enable auto height
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },

    });
}

export function countDown() {
    // Set the date to count down to
    const countDownDate = new Date("Jan 1, 2024 00:00:00").getTime();
    const x = setInterval(function() {
        // Get the current date and time
        const now = new Date().getTime();

        // Calculate the distance between the current date and the countdown date
        const distance = countDownDate - now;

        // Calculate days, hours, minutes and seconds left
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Add leading zeros to hours, minutes, and seconds
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        seconds = seconds.toString().padStart(2, '0');

        // Display the countdown in the separate span elements
        selectEl("#hours").children[0].innerHTML = hours.charAt(0);
        selectEl("#hours").children[1].innerHTML = hours.charAt(1);
        selectEl("#minutes").children[0].innerHTML = minutes.charAt(0);
        selectEl("#minutes").children[1].innerHTML = minutes.charAt(1);
        selectEl("#seconds").children[0].innerHTML = seconds.charAt(0);
        selectEl("#seconds").children[1].innerHTML = seconds.charAt(1);

        // If the countdown is over, display a message
        if (distance < 0) {
            clearInterval(x);
            selectEl("#countdown").innerHTML = "EXPIRED";
        }
    }, 1000);

}

// Home banner animation

function homeBanner() {
    const bannerImageClip = window.innerWidth > 990 && window.innerWidth <= 1024 ? "circle(66% at 80% 30%)" : "circle(68% at 72% 30%)";
    gsap.set(".home-banner__play-icon", {autoAlpha: 1})
    let tl = gsap.timeline();
    tl
        .to(".home-banner__image-1", {
            clipPath: bannerImageClip,
            ease: "power4.out",
        })
        .from(
            ".home-banner__play-icon",
            { y: -100, opacity: 0, ease: "back(3)" },
            1.5
        )
        .to(".home-banner__play-icon .text", {
            ease: 'none',
            rotate: -360,
            repeat: -1,
            duration: 8,
        })
        // Temporarily comment for the animation
        // .fromTo(".home-banner__image-1",
        //     { clipPath: bannerImageClip },
        //     {
        //         immediateRender: false,
        //         scrollTrigger: {
        //             trigger: '.counter',
        //             start: 'top bottom',
        //             end: '+=800',
        //             scrub: true,
        //             pin: '.home-banner__image-1'
        //         },
        //         clipPath: 'circle(62% at 76% 34%)',
        //     })
        // .to('.home-banner .home-banner__content',
        //     {
        //         y: -70,
        //         scrollTrigger: {
        //             trigger: '.counter',
        //             start: 'top bottom',
        //             end: '+=800',
        //             scrub: true,
        //             pin: '.home-banner .container'
        //         },
        //     })
    // return tl
}

function setSadFaces(sad = true) {
    let path = sad ?  "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
    gsap.to('.img-happy', {
        ease: 'steps(10)',
        clipPath: path,
    })
}

function noseBreatherReveal() {
    // Query the element
    const resizer = selectEl('#dragMe');
    const leftSide = resizer.previousElementSibling;
    const rightSide = resizer.nextElementSibling;

    // The current position of mouse
    let x = 0;
    let y = 0;

    // Width of left side
    let leftWidth = 0;

    const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = typeof e.clientX === 'undefined' ?  e.touches[0].clientX - x : e.clientX - x;
        const dy = typeof e.clientY === 'undefined' ?  e.touches[0].clientY - y : e.clientY - y;

        const newLeftWidth = ((leftWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;
        leftSide.style.userSelect = 'none';
        leftSide.style.pointerEvents = 'none';

        rightSide.style.userSelect = 'none';
        rightSide.style.pointerEvents = 'none';
    };

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function (e) {
        // Get the current mouse position
        x = typeof e.clientX === 'undefined' ?  e.touches[0].clientX : e.clientX;
        y = typeof e.clientY === 'undefined' ?  e.touches[0].clientY : e.clientY;
        leftWidth = leftSide.getBoundingClientRect().width;

        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('touchmove', mouseMoveHandler, {passive: true});
        document.addEventListener('mouseup', mouseUpHandler);
        document.addEventListener('touchend', mouseUpHandler, {passive: true});
    };

    // Attach the handler
    resizer.addEventListener('mousedown', mouseDownHandler);
    resizer.addEventListener('touchstart', mouseDownHandler, {passive: true});

    const mouseUpHandler = function () {
        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        leftSide.style.removeProperty('user-select');
        leftSide.style.removeProperty('pointer-events');

        rightSide.style.removeProperty('user-select');
        rightSide.style.removeProperty('pointer-events');

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('touchmove', mouseMoveHandler, {passive: true});
        document.removeEventListener('mouseup', mouseUpHandler);
        document.removeEventListener('touchend', mouseUpHandler, {passive: true});
    };
}

function initProblemSolutionsNav(revealTween, offset, panelWidth, gapPx) {
    const elms = selectAllEl('.problems-and-solutions__nav-items a')
    const start = revealTween.scrollTrigger.start

    elms.forEach(elm => {
        elm.addEventListener('click', (e) => {
            e.preventDefault()
            const jumpTo = elm.getAttribute('href').replace('#', '')
            const posOffset = window.innerWidth > 560 ? 360 : 180
            switch (jumpTo) {
                case 'the-problem':
                    scrollToHash(start)
                    break
                case 'the-symptoms':
                    scrollToHash(start + panelWidth + gapPx)
                    break
                case 'the-prevalence':
                    scrollToHash(start + panelWidth + gapPx + panelWidth + posOffset)
                    break
                case 'the-solution':
                    scrollToHash(start + panelWidth + gapPx + panelWidth + gapPx + panelWidth + posOffset)
                    break
                default:
                    break
            }
        })
    })
}

export function problemAndSolutions() {
    const demoItems = gsap.utils.toArray(".problems-and-solutions .demo-item");
    const wrapperWidth = selectEl('.mouth-breather-nose-breather').offsetWidth
    demoItems.forEach(item => {
        item.style.width = wrapperWidth + 'px'
    })
    noseBreatherReveal()
    // Problems and solutions section
    const panelArr = gsap.utils.toArray(".problems-and-solutions .panel");
    const container = selectEl(".problems-and-solutions .container");
    const panels = selectEl(".problems-and-solutions .panels");
    const panelsWidth = parseInt(panels.offsetWidth);
    const panelWidth = selectEl('.problems-and-solutions .panel').offsetWidth
    const gap = parseInt(getComputedStyle(panels).columnGap);
    const gapPx = (gap / 100) * panelsWidth;
    const gapPercent = (gapPx / container.offsetWidth) * 100;
    const offset = panelsWidth * 0.1;
    const scrollHeight = panelsWidth + offset;


    const revealTween = gsap.fromTo(
        ".mouth-breather-nose-breather .left",
        {
            width: 0,
        },
        {
            width: wrapperWidth + 'px',
            scrollTrigger: {
                trigger: ".problem",
                start: (0.5 * offset) + "px 30%",
                end: (0.5 * offset) + "px 20%",
                // markers: true,
                scrub: true,
            },
        }
    );

    initProblemSolutionsNav(revealTween, offset, panelWidth, gapPx)

    const offsetTop = window.innerHeight > 700 ? 120 : 100

    // Pin nav items without pin spacing
    gsap.to(".problems-and-solutions", {
        scrollTrigger: {
            trigger: ".problems-and-solutions__nav-items",
            start: `top ${offsetTop}`,
            end: "+=" + scrollHeight,
            pin: true,
            pinSpacing: false,
            // markers: true,
            id: "navItems",
        },
    });

    // Pin panels with pin spacing
    gsap.to(".problems-and-solutions", {
        scrollTrigger: {
            trigger: ".problems-and-solutions .panels",
            start: `top ${offsetTop + 60}`,
            end: "+=" + scrollHeight,
            pin: true,
            // markers: true,
            id: "problems-solutions-panels",
        },
    });

    gsap.fromTo(panelArr,{
            x: -(2 * (panelWidth + gapPx)),
        },
        {
            x: -(3 * (panelWidth + gapPx)),
            scrollTrigger: {
                trigger: '.problems-and-solutions',
                start: (2 * (offset + panelWidth) + gapPx)+"px top",
                end: "+=" + panelWidth,
                // markers: true,
                scrub: true
            }
        }
    )
    gsap.fromTo(panelArr,{
            x: -(panelWidth + gapPx),
        },
        {
            x: -(2 * (panelWidth + gapPx)),
            scrollTrigger: {
                trigger: '.problems-and-solutions',
                start: (offset + panelWidth + gapPx)+"px top",
                end: "+=" + panelWidth,
                // markers: true,
                scrub: true
            }
        }
    )
    gsap.fromTo(panelArr, {
            x: 0
        },
        {
            x: -(panelWidth + gapPx),
            scrollTrigger: {
                trigger: '.problems-and-solutions',
                start: offset+"px top",
                end: "+=" + panelWidth,
                // markers: true,
                scrub: true
            }
        }
    )

    // The problem
    const symptomsContainer = selectEl(".symptoms .top");
    const containerWidth = symptomsContainer.offsetWidth;
    const dominoUse = selectEl('.domino use')

    ScrollTrigger.create({
        trigger: '.problems-and-solutions',
        start: (panelWidth + gapPx) + ' top',
        end: "+=" + (offset * 0.5), // Make domino animation faster by reducing offset value to 50%
        // markers: true,
        scrub: true,
        ease: 'none',
        onUpdate: (self) => {
            const steps = [0.055, 0.275, 0.495, 0.605, 0.66, 0.715, 0.77, 0.825, 0.88, 0.935, 100]
            steps.every((step, i) => {
                if(self.progress <= step) {
                    dominoUse.setAttribute('href', '#domino-' + (i+1))
                    dominoUse.setAttribute('href', '#domino-' + (i+1))
                    return false
                }
                return true
            })
        }
    })

    const zero = { val: "0" };
    const percentEl = selectEl("#percentage");
    const toPercent = percentEl.getAttribute("data-percent");
    gsap.to(zero, {
        val: toPercent,
        duration: 1,
        ease: "power4.in",
        scrollTrigger: {
            trigger: '.problems-and-solutions',
            start: (offset + 2 * panelWidth + gapPx) + ' top',
            end: '+=' + (panelWidth * 0.75),
            // markers: true,
            toggleActions: "restart none restart reverse",
        },
        onUpdate: function () {
            const final = gsap.utils.snap(1, zero.val);
            percentEl.innerText = final;
        },
    });

    gsap.to(".progress-wrapper", {
        scrollTrigger: {
            trigger: ".problems-and-solutions",
            start: (offset + 2 * panelWidth + gapPx) + " top",
            end: "+="+(panelWidth * 0.5),
            // markers: true,
            onEnter: () => setSadFaces(),
            onLeaveBack: () => setSadFaces(false),
        },
    });
    let intervalCount = 0
    const vidInterval = setInterval(() => {
        const iframe = selectEl('.solution iframe')
        if(intervalCount > 100) clearInterval(vidInterval)
        intervalCount ++

        if(!iframe) return
        const parent = iframe.closest('.video-wrapper')
        const solutionsVideoPlayer = new Player(iframe);
        const solutionVideoAspectRatio = 728/494

        gsap.set('.solution .card-video .js-vimeo-player', {height: `${Math.round(parent.offsetWidth / solutionVideoAspectRatio )}px`})
        gsap.from(".solution .card-video", {
            scrollTrigger: {
                trigger: ".problems-and-solutions",
                start: (3 * (panelWidth + gapPx)) + " top",
                end: '+=' + panelWidth,
                // markers: true,
                onEnter: () => solutionsVideoPlayer.play(),
                onLeave: () => solutionsVideoPlayer.pause(),
                onEnterBack: () => solutionsVideoPlayer.play(),
                onLeaveBack: () => solutionsVideoPlayer.pause(),
            },
        });
        clearInterval(vidInterval)
    }, 50)

    gsap.from(".problems-and-solutions .asterisk", {
        opacity: 0,
        scale: gsap.utils.wrap([0.5, 1, 1.5]),
        rotate: gsap.utils.wrap([45, 100, 75]),
        top: gsap.utils.wrap([45, 100, 75]),
        scrollTrigger: {
            trigger: ".asterisks",
            start: "top 90%",
            end: "80% 70%",
            scrub: true,
        },
    });

    let top = 0;

    gsap.set(".panel", {
        zIndex: (i, target, targets) => targets.length + i,
    });
    panelArr.forEach((panel, i) => {
        let tl = gsap.timeline();
        tl.to(panel, {
            opacity: i + 1 < panelArr.length ? 0 : 1,
            // ease: "power4.in",
            ease: 'none',
        });

        if (i === 0 && !isPotraitMode) top = panel.offsetHeight / 2;

        const currentNavItem = selectEl(`.problems-and-solutions__nav-items li:nth-child(${i + 1})`);
        const nextNavItem = selectEl(`.problems-and-solutions__nav-items li:nth-child(${i + 2})`);

        ScrollTrigger.create({
            trigger: panel,
            start: top + "px 120px",
            end: "+=" + panel.offsetWidth,
            animation: tl,
            scrub: true,
            // markers: true,
            // id: 'panel_' + i,
            onUpdate: (self) => {
                if (self.progress > 0.6) {
                    currentNavItem?.classList?.remove("active");
                    nextNavItem?.classList?.add("active");
                } else {
                    currentNavItem?.classList?.add("active");
                    nextNavItem?.classList?.remove("active");
                }
            },
        });

        top +=
            panel.offsetWidth +
            (gapPercent * parseInt(container.offsetWidth)) / 100;
    });

    ScrollTrigger.matchMedia({
        '(max-width:750px)': () => {
            gsap.to('.problems-and-solutions__nav-items', {
                xPercent: -100,
                ease: "none", // <-- IMPORTANT!
                scrollTrigger: {
                    trigger: ".problems-and-solutions",
                    start: "100 top",
                    scrub: true,
                    end: "+=" + scrollHeight,
                    // markers: true,
                },
            });
        }
    })
}

export function liveBetter() {
    gsap.from('.liveBetter-item', {
        yPercent: 50,
        opacity: 0,
        stagger: 0.5,
        scrollTrigger: {
            trigger: '.liveBetter-wrap',
            start: 'top 80%',
            end: 'bottom 70%',
            scrub: true,
            // markers: true
        }
    })
}

function getStartedSteps() {
    ScrollTrigger.matchMedia({
        "(max-width: 990px)": () => {
            gsap.from(".get-started-steps .step", {
                opacity: 0,
                yPercent: 100,
                stagger: 0.5,
                scrollTrigger: {
                    trigger: ".get-started-steps",
                    start: "top bottom",
                    end: window.innerWidth < 991 ? "bottom 95%" : "bottom 30%",
                    // markers: true,
                    scrub: true,
                },
            });
        },
        "(min-width: 991px)": () => {
            const tl = gsap.timeline({ paused: true });
            gsap.set(".step.one", { zIndex: 2 });

            tl.to(".step.one .step__image", { opacity: 0 })
                .to(".step.two .step__image", { opacity: 1 }, "<")
                .to(".step.one .step__content", { yPercent: -400 }, "<")
                .fromTo(
                    ".step.two .step__content",
                    { opacity: 0, yPercent: 200 },
                    { opacity: 1, yPercent: 0 },
                    "<"
                )
                .to(".step.one", { zIndex: 0 }, "<")
                .to(".step.two", { zIndex: 2 }, "<")
                .to(".step.two .step__image", { opacity: 0 })
                .to(".step.three .step__image", { opacity: 1 }, "<")
                .to(".step.two .step__content", { yPercent: -400 }, "<")
                .fromTo(
                    ".step.three .step__content",
                    { opacity: 0, yPercent: 200 },
                    { opacity: 1, yPercent: 0 },
                    "<"
                )
                .to(".step.two", { zIndex: 0 }, "<");

            const height = isPotraitMode ? "200%" : "300%";
            gsap.to(".get-started-steps", {
                ease: "none",
                scrollTrigger: {
                    trigger: ".get-started-steps",
                    start: isPotraitMode ? "top top" : "60 top",
                    end: height + " bottom",
                    // markers: true,
                    pin: ".get-started-steps .headers",
                    id: "steps-header-pin",
                    pinSpacing: false,
                },
            });
            gsap.to(".get-started-steps", {
                ease: "none",
                scrollTrigger: {
                    trigger: ".get-started-steps",
                    start: isPotraitMode ? "top top" : "130 top",
                    end: height + " bottom",
                    // markers: true,
                    pin: ".get-started-steps .steps",
                    id: "steps-pin",
                    onUpdate: function (self) {
                        tl.progress(self.progress);
                        const iframe = selectEl('.step.three iframe')
                        if(!iframe) {
                            return
                        }
                        const wrapper = iframe.closest('.video-wrapper')
                        const video = new Player(iframe);
                        const isPlaying = wrapper.querySelector('.is-playing')
                        if(video) {
                            if(self.progress > 0.9 && !isPlaying) {
                                video.play()
                            } else if(self.progress < 0.75 && !video.paused) {
                                video.pause()
                            }
                        }
                    },
                },
            });
            gsap.to(".get-started-steps", {
                scrollTrigger: {
                    trigger: ".get-started-steps",
                    start: "bottom 300px",
                    end: "+=300px",
                    // markers: true,
                    onEnter: function() {
                        const iframe = selectEl('.step.three iframe')
                        const video = new Player(iframe);
                        if(video) {
                            video.pause()
                        }
                    },
                },
            });
        },
        "all": () => {
            const getStartedVideoAspectRatio = 728/494
            const parent = selectEl('.step.three .video-wrapper')
            gsap.set('.step.three .js-vimeo-player', {height: `${Math.round(parent.offsetWidth / getStartedVideoAspectRatio )}px`})
        }
    });
}

function counter() {
    const items = gsap.utils.toArray(".counter-item .fs-h1");
    ScrollTrigger.matchMedia({
        '(min-width: 768px)': () => {
            gsap.set('.counter-item', {
                y: -200
            })
            gsap.to('.counter-item', {
                y: -100,
                ease: 'none',
                opacity: 1,
                scrollTrigger: {
                    trigger: ".counter",
                    start: "top 55%",
                    end: "top 40%",
                    scrub: true,
                    // markers: true
                }
            })

            const waves = gsap.utils.toArray('.counter .parallax .bg')
            waves.forEach((wave, i) => {
                gsap.to(wave, {
                    y: -300 - (100 * i),
                    opacity: 1,
                    scrollTrigger: {
                        trigger: '.counter',
                        start: `top ${80 - i * 5}%`,
                        end: 'top top',
                        // markers: true,
                        scrub: true
                    }
                })
            })
        },
        'all': () => {
            gsap.from(items, {
                innerText: 0,
                modifiers: {
                    innerText: (inner) => {
                        if(typeof inner === 'number') {
                            return Math.floor(inner)
                        } else if(typeof inner === 'string') {
                            const hasPlus = inner.includes('+')
                            if(window.innerWidth < 769) {
                                const hasK = inner.includes('K')
                                let text = inner.replace(/\+| |,/g, '')
                                let num = parseInt(text)
                                if(hasK) {
                                    text = Math.floor(num) + 'K'
                                } else if(text > 1000) {
                                    text = Math.floor((num / 1000)).toString().replace(/\.0$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'K';
                                } else  {
                                    text = parseInt(num)
                                }
                                text  = hasPlus ? text + ' +': text
                                return text
                            }
                            let ret = parseInt(inner).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            ret = hasPlus ? ret + ' +' : ret
                            return ret
                        }
                        return inner
                    }
                },
                scrollTrigger: {
                    trigger: ".counter",
                    start: window.innerWidth > 768 ? 'top 50%':"top bottom",
                    end: "bottom 10%",
                    toggleActions: "play none restart reset",
                    // markers: true
                },
                stagger: {
                    each: 0.5,
                },
            });
        },
    })

    gsap.to('.counter-wrap .separator .line', {
        width: "50%",
        ease: 'none',
        // opacity: 1,
        scrollTrigger: {
            trigger: ".counter",
            start: "top 50%",
            end: "top 45%",
            scrub: true,
            // markers: true
        }
    })
}

export function info() {
    gsap.from(".info-wrap ", {
        y: 100,
        opacity: 0,
        scrollTrigger: {
            trigger: ".info",
            start: "top 90%",
            end: "50% 60%",
            scrub: true,
            // markers: true,
        },
    });
    gsap.from(".info-wrap .emoji-icon", {
        rotation: 15,
        scale: 0,
        scrollTrigger: {
            trigger: ".info",
            start: "top 50%",
            end: "75% 50%",
            scrub: true,
            // markers: true,
        },
    });
}
export function beforeAfterClick() {
    const beforeAfterNavs = gsap.utils.toArray(
        ".beforeAfter-item .tabs-nav_item"
    );
    beforeAfterNavs.forEach((navItem) => {
        navItem.addEventListener("click", () => {
            const parent = navItem.closest(".beforeAfter-item");
            const afterItem = parent.querySelector('figure[area-label="after"]');
            if (navItem.classList.contains("before")) {
                parent.querySelector(".tabs-nav_item.after").classList.remove("active");
                gsap.to(afterItem, {
                    clipPath: "polygon(0px 0px, 0% 0px, 0% 100%, 0% 100%)",
                    duration: 1,
                });
            } else {
                parent
                    .querySelector(".tabs-nav_item.before")
                    .classList.remove("active");
                gsap.to(afterItem, {
                    clipPath: "polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%)",
                    duration: 1,
                });
            }
            navItem.classList.add("active");
        });
    });
}
export function beforeAfterReveal() {
    const beforeAfterItems = gsap.utils.toArray(".beforeAfter-item");
    beforeAfterItems.forEach((item) => {
        const tl = gsap.timeline();
        const after = item.querySelector("figure + figure");
        tl.to(after, {
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
            duration: 4,
            ease: "none",
        });
        ScrollTrigger.create({
            trigger: item,
            start: "top 50%",
            end: "bottom 40%",
            // markers: true,
            animation: tl,
            scrub: true,
            onUpdate: (self) => {
                const before = item.querySelector(".before");
                const after = item.querySelector(".after");
                if(self.progress === 1) {
                    tl.kill()
                } else if (self.progress > 0.5) {
                    after.classList.add("active");
                    before.classList.remove("active");
                } else {
                    after.classList.remove("active");
                    before.classList.add("active");
                }

            },
        });
    });
}

function paymentPlan() {
    ScrollTrigger.create({
        trigger: ".payment",
        start: "top top",
        end: window.innerWidth > 990 ? "120% bottom" : "bottom bottom",
        // markers: true,
        pin: window.innerWidth > 990 ? ".payment .container" : false,
    });

    gsap.from(".payment-options_card", {
        y: 300,
        stagger: 0.5,
        scrollTrigger: {
            trigger: ".payment",
            start: "top 70%",
            end: "bottom bottom",
            scrub: true,
            // markers: true,
        },
    });
}

export function blogItems() {
    ScrollTrigger.matchMedia({
        "(max-width: 990px)": () => {
            const blogItems = gsap.utils.toArray(".blog-item");
            blogItems.forEach((blogItem) => {
                gsap.from(blogItem, {
                    opacity: 0,
                    xPercent: 100,
                    scrollTrigger: {
                        trigger: blogItem,
                        start: "top 90%",
                        end: "top 30%",
                        scrub: true,
                        // markers: true
                    },
                });
            });
        },
        "(min-width: 991px)": () => {
            gsap.from(".blog-item", {
                opacity: 0,
                yPercent: 100,
                stagger: 0.5,
                scrollTrigger: {
                    trigger: ".education-refer",
                    start: "top 90%",
                    end: "top 30%",
                    scrub: true,
                },
            });
        },
        all: () => {
            gsap.from(".acc-item", {
                opacity: 0,
                yPercent: 100,
                stagger: 0.5,
                scrollTrigger: {
                    trigger: ".accordion",
                    start: "top 50%",
                    end: "top 10%",
                    scrub: true,
                },
            });
        },
    });
}

export const  callToAction = () => {
    gsap.from(".cta-container", {
        rotate: 15,
        opacity: 0,
        scrollTrigger: {
            trigger: ".cta",
            start: "top 70%",
            end: "bottom bottom",
            scrub: true,
            // markers: true,
        },
    });
}

// GSAP Animation Home page
window.addEventListener("load", () => {
    if (!document.body.classList.contains("home")) return; // Do not execute on pages other than home page
    isPotraitMode = window.innerWidth < window.innerHeight;

    homeBanner();
    info()
    problemAndSolutions();
    liveBetter()
    getStartedSteps();
    beforeAfterClick();
    beforeAfterReveal();
    testimonialAnimation();
    paymentPlan();
    blogItems();
    callToAction();
    counter();
    countDown();
    tempNavigationLinks()
});

//  Remove this once the pages are done
// Nav scroll to start
export function tempNavigationLinks() {
    const temporaryLinks = document.querySelectorAll("a.temporary");
    if (temporaryLinks.length < 1) return

    document.querySelectorAll("a.temporary").forEach((item, index) => {
        item.addEventListener("click", (event) => {
            event.preventDefault();
            const link = item.getAttribute('href')
            const linkArr = link.split('#')
            const targetId = linkArr.length > 1 ? linkArr[1] : linkArr[0]
            const targetEL = document.getElementById(targetId);
            const target = event.target;
            const hash = target.hash;

            if(!targetEL && linkArr.length > 1) {
                window.location.href = link
                return
            }
            scrollToHash(hash)
        });
    });

    const url = window.location.href
    if(url.includes('#')) {
        const targetId = url.split('#')[1]
        scrollToHash('#' + targetId)
    }
}
// Nav scroll to end

window.addEventListener('resize', function() {
    containerSpacing();
    articlesCard();
})
